<template>
        <!-- box describ -->

        <div class="flex justify-between items-center">
            <!-- compatible -->
            <div
                data-aos="slide-right"
                class="mx-auto space-y-3"
            >
                <div class="text-xs sm:text-lg md:text-xl"><p>{{$t('iconSpec.compatible.title')}}</p></div>
                <div class="text-xs sm:text-lg md:text-xl xl:text-4xl overflow-hidden">
                    <div
                        data-aos="slide-right"
                        class="w-full flex justify-between gap-4 lg:gap-10">
                        <div class="text-center">
                            <ImgLoading
                                :AttributeImg="{
                                        source:'/images/icons/icon-apple.svg',
                                        altText:$t('iconSpec.compatible.appleAlt'),
                                        titleText:$t('iconSpec.compatible.appleTitle'),
                                        className:brandicon,
                                        width: '60',
                                        height: '60'
                                    }"
                            />
                            <p>Apple</p>
                        </div>
                        <div class="text-center">
                            <ImgLoading
                                :AttributeImg="{
                                        source:'/images/icons/icon-android.svg',
                                        altText:$t('iconSpec.compatible.androidAlt'),
                                        titleText:$t('iconSpec.compatible.androidTitle'),
                                        className:brandicon,
                                        width: '60',
                                        height: '60'
                                    }"
                            />
                            <p>Android</p>
                        </div>
                        <div class="text-center">
                            <ImgLoading
                                :AttributeImg="{
                                        source:'/images/icons/icon-microsoft.svg',
                                        altText:$t('iconSpec.compatible.microsoftAlt'),
                                        titleText:$t('iconSpec.compatible.microsoftTitle'),
                                        className:brandicon,
                                        width: '60',
                                        height: '60'
                                    }"
                            />
                            <p>Microsoft</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- glasses -->
            <div
                data-aos="fade-up-left"
                class="w-full"
            >
                <ImgLoading
                    :AttributeImg="{
                                    source:'/images/landing_page/lunettes_connectees.webp',
                                    altText:$t('pageSport.advancedAudioFeaturesImgAlt'),
                                    titleText:$t('pageSport.advancedAudioFeaturesImgTitle'),
                                    className:'w-full md:6/12 lg:w-7/12 mx-auto object-cover',
                                    srcset: `/images/landing_page/lunettes_connectees-sm.webp 480w,
                                            /images/landing_page/lunettes_connectees-md.webp 800w,
                                            /images/landing_page/lunettes_connectees-lg.webp 1200w,
                                            /images/landing_page/lunettes_connectees-xl.webp 2400w,
                                            /images/landing_page/lunettes_connectees.webp 3600w`,
                                    sizes: `(max-width: 480px) 100vw,
                                        (max-width: 800px) 50vw,
                                        (max-width: 1200px) 33vw,
                                        (max-width: 2400px) 25vw,
                                        (max-width: 3200px) 20vw,
                                        16vw`,
                                    width: '3600',  // Dimensions de l'image la plus grande
                                    height: '800'
                                }"
                />
            </div>
        </div>
        <div class="h-full mt-6">
            <div class="flex justify-between text-center text-xl lg:text-4xl xl:text-6xl">
                <div class="flex-1 border-r-2 border-elionPurple-800">
                    <p class="custom-h2"><font-awesome-icon :icon="faMobile" /> <span class="custom-text-gradient">{{$t('landingPage.sectionLanding.titleCall')}}</span></p>
                </div>
                <div class="flex-1 border-r-2 border-elionPurple-800">
                    <p class="custom-h2"><font-awesome-icon :icon="faMusic" /> <span class="custom-text-gradient">{{$t('landingPage.sectionLanding.titleMusic')}}</span></p>
                </div>
                <div class="flex-1">
                    <p class="custom-h2"><font-awesome-icon :icon="faBrain" /> <span class="custom-text-gradient">{{$t('landingPage.sectionLanding.titleSmart')}}</span></p>
                </div>
            </div>
            <div class="flex justify-between text-center text-sm">
                <div class="flex-1 p-2">
                    <p>{{$t('landingPage.sectionLanding.textCall')}}</p>
                </div>
                <div class="flex-1 p-2">
                    <p>{{$t('landingPage.sectionLanding.textMusic')}}</p>
                </div>
                <div class="flex-1 p-2">
                    <p>{{$t('landingPage.sectionLanding.textSmart')}}</p>
                </div>
            </div>
        </div>
        <div data-aos="fade-top"
             class="w-full rounded-md lg:rounded-2xl overflow-hidden">
            <ImgLoading
                :AttributeImg="{
                                source:'/images/landing_page/lunettes-connectees-boite.webp',
                                altText:$t('landingPage.diversiteStyleImgAlt'),
                                titleText:$t('landingPage.diversiteStyleImgTitle'),
                                className:'w-full lg:w-7/12 mx-auto object-cover',
                                srcset: `/images/landing_page/lunettes-connectees-boite-sm.webp 480w,
                                        /images/landing_page/lunettes-connectees-boite-md.webp 800w,
                                        /images/landing_page/lunettes-connectees-boite-lg.webp 1200w,
                                        /images/landing_page/lunettes-connectees-boite-xl.webp 2400w,
                                        /images/landing_page/lunettes-connectees-boite.webp 3600w`,

                                sizes: `(max-width: 480px) 100vw,
                                    (max-width: 800px) 50vw,
                                    (max-width: 1200px) 33vw,
                                    (max-width: 2400px) 25vw,
                                    (max-width: 3200px) 20vw,
                                    16vw`,
                                width: '3600',  // Dimensions de l'image la plus grande
                                height: '2400'
                        }"
            />
        </div>
</template>

<script setup>
    import Parallax from 'parallax-js';
    /*
    import SonSpaciale from "@/Components/icons/SonSpaciale.vue";
    import MicrophoneHdReductionBruit from "@/Components/icons/MicrophoneHdReductionBruit.vue";
    import Bluetooth from "@/Components/icons/Bluetooth.vue";
    import BloqueLesLumieresBleue from "@/Components/icons/BloqueLesLumieresBleue.vue";
    import VerreResistant from "@/Components/icons/VerreResistant.vue";
    import UvaUvbProtetion from "@/Components/icons/UvaUvbProtetion.vue";
    import Compatible from "@/Components/Compatible.vue";
    */
    import {onMounted, onUnmounted, ref} from "vue";
    import ImgLoading from "@/Components/ImgLoading.vue";
    import {faMusic,faMobile,faBrain} from "@fortawesome/free-solid-svg-icons";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

    const parallaxContainer = ref(null);
    const brandicon = "h-7 lg:h-24 mx-auto"
    let parallaxInstance = null;
    onMounted(() => {
        if (parallaxContainer.value) {
            parallaxInstance = new Parallax(parallaxContainer.value, {
                relativeInput: true,
                hoverOnly: true
            });
        }
    });
    onUnmounted(() => {
        if (parallaxInstance) {
            parallaxInstance.disable();
        }
    });
</script>
<style>
    .parallax-container {
        position: relative;
        width: 100%; /* ou toute autre taille appropriée */
        height: 500px; /* Assurez-vous que le conteneur a une hauteur */
        overflow: hidden;
    }
</style>
